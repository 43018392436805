<template>
<!-- eslint-disable max-len -->
  <section class="Page AboutUs">
  <h2 class="Title Title--type-h2 Page-Title AboutUs-Title" v-html="$t('aboutUs.title')">
  </h2>
  <div v-for="(section, i) in $t('aboutUs.sections')" :key="i" class="Page-Section AboutUs-Section">
    <h3 v-html="section.title">
    </h3>
    <div v-html="section.text">
    </div>
  </div>
</section>
</template>

<script>
export default {
  name: 'AboutUs',
  metaInfo() {
    return {
      title: this.$t('metaInfo.aboutUs.title'),
      meta: [
        {
          name: 'description',
          content:
            this.$t('metaInfo.aboutUs.description'),
        },
      ],
    };
  },
};
</script>

<style lang="scss">
.AboutUs {
  &-Section {
    margin-bottom: 10px;
  }

  &-Title {
    margin-bottom: 30px;
    padding-left: 20px;

    @media (min-width: $screen-m) {
      margin-bottom: 40px;
      padding-left: 0;
    }

    @media (min-width: $screen-l) {
      margin-bottom: 50px;
    }

    @media(min-width: $screen-xl) {
      margin-bottom: 60px;
    }
  }
}

</style>
